import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer/Footer"

function Imprint() {
  return (
    <Layout nav="false">
      <SEO />
      <div className="container impressum py-3 px-0">
        <h3 className="mb-3 font-weight-bold mt-4">Angaben gemäß § 5 TMG</h3>
        Sanktionsfrei e.V. (gemeinnützig)
        <br />
        Am Sudhaus 2<br />
        12053 Berlin
        <br />
        Tel: <a href="tel:+49 157 741 635 60">+49 157 741 635 60</a>
        <br />
        <h3 className="mb-3 font-weight-bold mt-4">
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
        </h3>
        Helena Kilian-Steinhaus
        <h3 className="mb-3 font-weight-bold mt-4">Kontakt, Fragen & Hilfe</h3>
        <a href="mailto:info@sanktionsfrei.de">info@sanktionsfrei.de</a>
        <div className="mb-3">
          <h3 className="mb-3 font-weight-bold mt-4">Presseanfragen</h3>
          Tel: <a href="tel:+49 178 189 1815">+49 157 741 635 60</a>
          <br />
          E-Mail:{" "}
          <a href="mailto:presse@sanktionsfrei.de">presse@sanktionsfrei.de</a>
        </div>
        <div className="mb-3">
          <h3 className="mb-3 font-weight-bold mt-4">Spenden</h3>
          IBAN: DE53 4306 0967 1181 4587 00
          <br />
          BIC: GENODEM1GLS
          <br />
          Bank: GLS Gemeinschaftsbank eG
          <br />
          Verwendungszweck: Spende
          <br />
        </div>
        <h3 className="mb-3 font-weight-bold mt-4">Registereintrag</h3>
        Eintragung im Vereinsregister
        <br />
        Amtsgericht Charlottenburg
        <br />
        Registernummer: VR 35037 B<br />
        <a className="" href="/images/satzung.pdf">
          Vereinssatzung
        </a>
        <br />
      </div>
      <Footer />
    </Layout>
  )
}

export default Imprint
